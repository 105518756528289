// Import libraries
import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { faLongArrowLeft } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


// Import global styles
import GlobalStyle from '../styles/global';


// Import component
import Layout from '../layout/Layout';
import SEO from '../layout/SEO';


// Component styles
const StyledSuccess = styled.div`
    display: grid;
    grid-template-rows: repeat(3, max-content);
    grid-row-gap: 2rem;
    align-content: center;
    padding: 10rem var(--padding-right) 10rem var(--padding-left);
    background-color: var(--color-background-2);
    min-height: calc(100vh - 266px);

    @media only screen and (max-width: 1000px) {
        min-height: calc(100vh - 212px);
    }

    @media only screen and (max-width: 500px) {
        min-height: calc(100vh - 170px);
    }

    & h1 {
        font-size: var(--font-size-body-h1);
        font-weight: var(--font-weight-body-h1);
        line-height: var(--line-height-body-h1);
    }
    
    & p {
        font-size: var(--font-size-body-p);
        font-weight: var(--font-weight-body-p);
        line-height: var(--line-height-body-p);
    }

    & .link {
        justify-self: start;
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        background-color: var(--color-primary);
        border: 2px solid var(--color-primary);
        border-radius: 0;
        font-size: 2rem;
        padding: 1rem 2rem;
        color: #fff;
        font-weight: 400;

        & span {
            margin-left: 10px;
        }
    }

    & .link:link,
    & .link:visited {
        color: #fff;
        text-decoration: none;
        outline: 0;
        transition: color .25s ease;
    }

    & .link:hover,
    & .link:focus {
        text-decoration: none;
        color: #fff;
        outline: 0;
        transform: translateY(-3px);
        box-shadow: 5px 5px 5px rgba(0, 0, 0, .1);
    }

    & .link:active {
        text-decoration: none;
        color: #fff;
        outline: 0;
        transform: translateY(0);
        box-shadow: none;
    } 
`;


// Create component
const SuccessPage = () => {
    const [successType, setSuccessType] = useState('');


    useEffect(() => {
        // Set success type, because window is not defined in SSR
        setSuccessType(window.location.search.replace('?type=', ''));
    }, []);


    return (
        <React.Fragment>
            <GlobalStyle />
            <SEO title="Success" />
            <Layout>
                <StyledSuccess>
                    {successType === 'call-me-back' ? (
                        <React.Fragment>
                            <h1>We will call you back</h1>

                            <p>Thank you for leaving your phone number for us. We will give you a call back ASAP.</p>
        
                            <Link to="/" className="link">
                                <FontAwesomeIcon icon={faLongArrowLeft} className="icon" />
                                <span>Go Back</span>
                            </Link>
                        </React.Fragment>
                    ) : successType === 'contact-form' && (
                        <React.Fragment>
                            <h1>Form Submission</h1>

                            <p>Thank you for filling out the contact form. We will get back to you ASAP.</p>
        
                            <Link to="/" className="link">
                                <FontAwesomeIcon icon={faLongArrowLeft} className="icon" />
                                <span>Go Back</span>
                            </Link>
                        </React.Fragment>
                    )}
                </StyledSuccess>
            </Layout>
        </React.Fragment>
    );
};


// Export component by default
export default SuccessPage;